<template>
	<form @submit.prevent>
		<template v-for="(item, index) in itemsNeedingInfo" :key="index">
			<student-information
				:index="index"
				:line-item="item"
				:display-index="$store.getters.totalYearbooks > 1 && itemsNeedingInfo.length > 1"
				:is-yearbook="item.product.id === 'yearbook'"
				ref="student-info"
				@next-step="nextStep"
			/>
		</template>

		<div class="text-h5">
			<v-icon>email</v-icon> Where can we reach you?
		</div>

		<v-text-field
			v-model="email"
			label="Email"
			:error-messages="emailErrors"
			class="required"
			@keyup.enter="nextStep"
			type="email"
			variant="underlined"
		/>

		<v-btn
			text
			@click="prevStep"
		>
			Back
		</v-btn>
		<v-btn
			color="primary"
			@click="nextStep"
			style="margin-left: 1em"
		>
			Continue
		</v-btn>
	</form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import StudentInformation from './StudentInformation.vue';

export default {
	name: 'PersonalInformation',
	emits: ['next-step'],
	setup() {
		return {
			v$: useVuelidate()
		};
	},
	data: function() {
		return {
			email: this.$store.state.cart.email
		};
	},
	validations: {
		email: { required, email }
	},
	computed: {
		itemsNeedingInfo() {
			return this.$store.state.cart.items.filter((item) => {
				return item.product.id !== 'adopt-a-book';
			});
		},
		emailErrors() {
			const errors = [];
			if(this.v$.email.$dirty) {
				!this.v$.email.required.$invalid || errors.push('Email is required');
				!this.v$.email.email.$invalid || errors.push('Email is not valid');
			}

			return errors;
		},
		firstLineItem() {
			let lineItem = this.$store.state.cart.items[0];
			if(lineItem && lineItem.product.id === 'yearbook') {
				return lineItem;
			} else {
				return null;
			}
		}
	},
	watch: {
		'firstLineItem.firstName'(newVal, oldVal) {
			this.updateBlankAds('firstName', newVal, oldVal);
		},
		'firstLineItem.lastName'(newVal, oldVal) {
			this.updateBlankAds('lastName', newVal, oldVal);
		},
		'firstLineItem.teacher'(newVal, oldVal) {
			this.updateBlankAds('teacher', newVal, oldVal);
		},
		'firstLineItem.grade'(newVal, oldVal) {
			this.updateBlankAds('grade', newVal, oldVal);
		}
	},
	methods: {
		nextStep: function() {
			this.v$.$touch();

			let error = this.v$.$error;
			if(this.$refs['student-info']) {
				if(this.$refs['student-info'].forEach) {
					this.$refs['student-info'].forEach((studentInfo) => {
						studentInfo.v$.$touch();
						error = error || studentInfo.v$.$error;
					});
				} else {
					let studentInfo = this.$refs['student-info'];
					studentInfo.v$.$touch();
					error = error || studentInfo.v$.$error;
				}
			}

			if(!error) {
				this.$store.commit('setPersonalInformation', {
					email: this.email
				});

				let fullName = this.$store.getters.fullName;
				if(fullName && this.$store.state.cart.email && window.LogRocket) {
					window.LogRocket.identify(this.$store.state.cart.email, {
						name: fullName,
						email: this.$store.state.cart.email
					});
				}

				this.$emit('next-step');
			}
		},
		prevStep: function() {
			this.$router.push('/projects/' + this.$store.state.project.id);
			document.documentElement.scrollTop = 0;
		},
		updateBlankAds(prop, newVal, oldVal) {
			this.$store.state.cart.items.filter((lineItem) => {
				return lineItem.product.id === 'ads';
			}).forEach((lineItem) => {
				if(lineItem[prop] == '' || lineItem[prop] === oldVal) {
					lineItem[prop] = newVal;
				}
			});
		}
	},
	components: {
		StudentInformation
	}
};
</script>

<style>
	.v-input.required label::after {
		content: " *";
		color: rgb(var(--v-theme-error));
	}
</style>