import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, md } from 'vuetify/iconsets/md';
import { BASE_COLOR, BRAND_BASE_COLOR } from '../styles/colors';

export default createVuetify({
	theme: {
		themes: {
			light: {
				dark: false,
				colors: {
					primary: BRAND_BASE_COLOR.highVolume,
					success: BASE_COLOR.emerald['500'],
					error: BASE_COLOR.red['600'],
					warning: BASE_COLOR.amber['500'],

					headerPrimary: BASE_COLOR.zink['900'],
				}
			}
		}
	},
	icons: {
		defaultSet: 'md',
		aliases,
		sets: {
			md
		}
	}
});