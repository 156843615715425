<template>
	<div>
		<div class="text-h5">
			<v-icon>person</v-icon>
			<template v-if="!isYearbook">
				For which <i class="student">{{ $store.state.project.checkoutStudentLabel }}</i> are you buying this ad?
			</template>
			<template v-else-if="displayIndex">
				For which <i class="student">{{ $store.state.project.checkoutStudentLabel }}</i> are you buying the <b><i>{{ ordinal(index + 1) }}</i></b> book?
			</template>
			<template v-else>
				For which <i class="student">{{ $store.state.project.checkoutStudentLabel }}</i> are you buying this book?
			</template>
		</div>

		<template v-for="field in displayFields">
			<v-combobox
				v-model="lineItem[fieldId(field)]"
				:items="getValuesForField(field)"
				:label="field.field"
				:error-messages="fieldErrors(field)"
				:class="{ required: field.required }"
				class="info-dropdown"
				auto-select-first
				:key="field.field + '-autocomplete'"
				variant="underlined"
				:hide-no-data="false"
				v-if="getValuesForField(field)"
			/>

			<v-text-field
				v-model="lineItem[fieldId(field)]"
				:label="field.field"
				:error-messages="fieldErrors(field)"
				:class="{ required: field.required }"
				variant="underlined"
				@keyup.enter="nextStep"
				:key="field.field + '-text'"
				v-else
			/>
		</template>

		<p />
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import getFieldId from '../utils/get-field-id';
import getDisplayFields from '../utils/get-display-fields';
import { ordinal } from '@/components/utils/format-money';

export default {
	name: 'StudentInformation',
	emits: ['next-step'],
	setup() {
		return {
			v$: useVuelidate()
		};
	},
	props: ['index', 'displayIndex', 'lineItem', 'isYearbook'],
	validations() {
		let lineItem = {};

		this.displayFields.forEach(field => {
			if(field.required) {
				let id = this.fieldId(field);
				lineItem[id] = {
					required
				};

				if(!this.getValuesForField(field)) {
					lineItem[id].minLength = minLength(2);
				}
			}
		});
		
		return {
			lineItem
		};
	},
	computed: {
		displayFields() {
			return getDisplayFields(this.$store, this.lineItem);
		}
	},
	methods: {
		nextStep: function() {
			this.$emit('next-step');
		},

		fieldId(field) {
			return getFieldId(field);
		},
		fieldErrors(field) {
			const errors = [];
			let id = this.fieldId(field);
			if(this.v$.lineItem && this.v$.lineItem[id] && this.v$.lineItem[id].$dirty) {
				if(field.required) {
					!this.v$.lineItem[id].required.$invalid || errors.push(field.field + ' is required');

					if(!this.getValuesForField(field)) {
						!this.v$.lineItem[id].minLength.$invalid || errors.push(field.field + ' must be at least 2 characters long');
					}
				}
			}

			return errors;
		},

		getValuesForField(field) {
			let subjectFieldValues = this.$store.state.project.subjectFieldValues;
			if(!subjectFieldValues) {
				return null;
			}

			let fieldValues;
			if(field.field === 'Teacher/Home Room') {
				fieldValues = subjectFieldValues['Teacher'] ?? subjectFieldValues['Home Room'];
			} else {
				fieldValues = subjectFieldValues[field.field];
			}
			if(!fieldValues) {
				return null;
			}

			let values = Object.keys(fieldValues);
			// Want to filter out cases where we have bogus data where it is just a single grade choice of Grade (happened due to a Subject Management bug)
			if(!values.length || (values.length === 1 && [field.field.toLowerCase(), 'teacher', 'grade', 'home room'].includes(values[0].toLowerCase()))) {
				return null;
			}

			values.sort((a, b) => {
				return a.toLowerCase().localeCompare(b.toLowerCase());
			});

			return values;
		},

		ordinal
	}
};
</script>

<style scoped>
.student {
	color: rgb(var(--v-theme-error));
}
</style>